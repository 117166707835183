/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "von Neumann entropy"), " of a quantum state is a quantity describing how much the information in the state can be compressed while still being recoverable."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "The von Neumann entropy is the quantum-mechanical analogue of the ", React.createElement(_components.strong, null, "Shannon entropy"), ". Under certain circumstances, the two quantities agree with each other, but under others, the von Neumann entropy can be lower than the Shannon entropy (meaning that the information can be compressed further)."), "\n", React.createElement(_components.p, null, "The von Neumann entropy is given by the formula"), "\n", React.createElement(_components.p, null, "$$\nS(\\rho) = -\\text{tr}(\\rho \\log \\rho)\n$$"), "\n", React.createElement(_components.p, null, "where $\\rho$ is the density matrix representing a quantum state."), "\n", React.createElement(_components.p, null, "It is also useful to define the ", React.createElement(_components.strong, null, "relative von Neumann entropy"), ". For two density matrices, $\\rho$ and $\\sigma$, this quantity is given by the formula"), "\n", React.createElement(_components.p, null, "$$\nS(\\rho||\\sigma) = \\text{tr}(\\rho \\log \\rho) -\\text{tr}(\\rho \\log \\sigma),\n$$"), "\n", React.createElement(_components.p, null, "the value of which is always non-negative."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.vn_entropy.html"
  }, "von Neumann entropy in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.vn_entropy"), ". This is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/quantum_info#entropy"
  }, React.createElement(_components.code, null, "quantum_info.entropy"), " in Qiskit"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
