import React from "react";
import GATSBY_COMPILED_MDX from "/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-von-neumann-entropy/index.mdx";
import {MDXProvider} from '@mdx-js/react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import ContentSidebar from '../../components/ContentSidebar/ContentSidebar';
import DemosLayout from '../../components/DemosLayout/DemosLayout';
import Footnotes from '../../components/Footnotes/Footnotes';
import LinkComponent from '../../components/LinkComponent/LinkComponent';
import MarkdownText from '../../components/MarkdownText/MarkdownText';
import Notes from '../../components/Notes/Notes';
import ParagraphMDX from '../../components/ParagraphMDX/ParagraphMDX';
import PermaLink from '../../components/PermaLink/PermaLink';
import Seo from '../../components/seo';
import './style.scss';
import {jsx as _jsx} from "react/jsx-runtime";
import {jsxs as _jsxs} from "react/jsx-runtime";
import {Fragment as _Fragment} from "react/jsx-runtime";
const shortcodes = {
  Notes,
  Footnotes,
  MarkdownText,
  PermaLink,
  a: LinkComponent,
  p: ParagraphMDX
};
function GlossaryPageTemplate(_ref) {
  let {data, children} = _ref;
  const {mdx} = data;
  const frontmatter = mdx === null || mdx === void 0 ? void 0 : mdx.frontmatter;
  const tableOfContents = mdx === null || mdx === void 0 ? void 0 : mdx.tableOfContents;
  return _jsxs(_Fragment, {
    children: [_jsx(Breadcrumbs, {
      pageTitle: (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title) || ''
    }), _jsx(DemosLayout, {
      mainSection: _jsxs("section", {
        className: "glossary-item-container w-[830px]",
        children: [(frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title) && _jsxs("h1", {
          children: [frontmatter.title, " ", _jsx(PermaLink, {
            title: frontmatter.title
          })]
        }), _jsx(MDXProvider, {
          components: shortcodes,
          children: children
        })]
      }),
      sidebarSection: tableOfContents !== null && tableOfContents !== void 0 && tableOfContents.items && tableOfContents.items instanceof Array && tableOfContents.items.length > 0 ? _jsx(ContentSidebar, {
        linksList: tableOfContents === null || tableOfContents === void 0 ? void 0 : tableOfContents.items
      }) : undefined
    })]
  });
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(GlossaryPageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = _ref2 => {
  var _data$mdx, _data$mdx$frontmatter, _data$mdx2, _data$mdx2$frontmatte, _data$mdx3, _data$mdx3$frontmatte, _data$mdx4, _data$mdx4$frontmatte, _data$mdx5, _data$mdx5$frontmatte;
  let {data} = _ref2;
  return _jsx(Seo, {
    title: (_data$mdx = data.mdx) !== null && _data$mdx !== void 0 && (_data$mdx$frontmatter = _data$mdx.frontmatter) !== null && _data$mdx$frontmatter !== void 0 && _data$mdx$frontmatter.meta_title ? data.mdx.frontmatter.meta_title : ((_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : (_data$mdx2$frontmatte = _data$mdx2.frontmatter) === null || _data$mdx2$frontmatte === void 0 ? void 0 : _data$mdx2$frontmatte.title) || '',
    image: ((_data$mdx3 = data.mdx) === null || _data$mdx3 === void 0 ? void 0 : (_data$mdx3$frontmatte = _data$mdx3.frontmatter) === null || _data$mdx3$frontmatte === void 0 ? void 0 : _data$mdx3$frontmatte.meta_image) || '',
    description: ((_data$mdx4 = data.mdx) === null || _data$mdx4 === void 0 ? void 0 : (_data$mdx4$frontmatte = _data$mdx4.frontmatter) === null || _data$mdx4$frontmatte === void 0 ? void 0 : _data$mdx4$frontmatte.meta_description) || '',
    pathname: "/qml/glossary/" + ((_data$mdx5 = data.mdx) === null || _data$mdx5 === void 0 ? void 0 : (_data$mdx5$frontmatte = _data$mdx5.frontmatter) === null || _data$mdx5$frontmatte === void 0 ? void 0 : _data$mdx5$frontmatte.slug)
  });
};
const glossaryQuery = "2612406388";
